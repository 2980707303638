import $ from 'jquery';

import axios from "axios";

const clientstories = (() => {
  const config = {
    get triggers() {
      return this.triggerCN
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    triggerCN: ['js-story'],
    loadMoreButtonCN: ['load_more'],
    postContainer: 'clientstory-container',
    loadingCN: 'loading',
    noMoreItemsCN: 'done',
  };

  const handleSuccess = (clientstoriesWrapper, response) => {
    // Update the parameters
    clientstoriesWrapper.dataset.offset =
      parseInt(clientstoriesWrapper.dataset.offset, 10) +
      response.data.items.length;
    clientstoriesWrapper.dataset.max = parseInt(response.data.max, 10);

    // Add the HTML
    response.data.items.forEach(content => {
      clientstoriesWrapper
        .getElementsByClassName(config.postContainer)[0]
        .insertAdjacentHTML('beforeend', content);
    });
  };

  const handleError = clientstoriesWrapper => {
    clientstoriesWrapper.innerHTML =
      'Something went wrong, please try again later';
    // console.warn(clientstoriesWrapper, error);
  };

  const getClientstories = clientstoriesWrapper => {
    clientstoriesWrapper.classList.add(config.loadingCN);

    axios
      .get('/wp-json/v1/clientstories', {
        params: {
          offset: clientstoriesWrapper.dataset.offset,
          service: clientstoriesWrapper.dataset.service,
          industry: clientstoriesWrapper.dataset.industry,
        },
      })
      .then(response => {
        if (response.status === 200) {
          handleSuccess(clientstoriesWrapper, response);
        } else {
          handleError(clientstoriesWrapper, 'Something went wrong');
        }
      })
      .catch(error => {
        handleError(clientstoriesWrapper, error);
      })
      .then(() => {
        clientstoriesWrapper.classList.remove(config.loadingCN);

        const offset = parseInt(clientstoriesWrapper.dataset.offset, 10);
        const max = parseInt(clientstoriesWrapper.dataset.max, 10);

        if (offset >= max)
          clientstoriesWrapper.classList.add(config.noMoreItemsCN);
      });
  };

  const reset = clientstoriesWrapper => {
    clientstoriesWrapper.dataset.offset = 0;
    clientstoriesWrapper.dataset.max = 0;
    clientstoriesWrapper.getElementsByClassName(
      config.postContainer,
    )[0].innerHTML = '';
    clientstoriesWrapper.classList.remove(config.noMoreItemsCN);
  };

  const changeSelect = (clientstoriesWrapper, select) => {
    const selectedOptions = Array.prototype.slice.call(select.selectedOptions);

    const selectedValues = selectedOptions.map(selectedOption => {
      return selectedOption.value;
    });

    clientstoriesWrapper.setAttribute(
      `data-${select.name}`,
      selectedValues.join(),
    );

    reset(clientstoriesWrapper);
    getClientstories(clientstoriesWrapper);
  };

  const init = () => {
    $(() => {
      if (config.triggers.length > 0) {
        document
          .querySelectorAll(`.${config.triggerCN}`)
          .forEach(clientstoriesWrapper => {
            clientstoriesWrapper
              .querySelectorAll(`.${config.loadMoreButtonCN}`)
              .forEach(btn => {
                btn.addEventListener('click', () =>
                  getClientstories(clientstoriesWrapper),
                );
              });
            $('select').on('change', e => {
              changeSelect(clientstoriesWrapper, e.target);
            });
          });
      }
    });
  };

  return {
    init,
  };
})();

clientstories.init();
