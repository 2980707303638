const Copy = (() => {
  const config = {
    get triggers() {
      return this.triggerCN
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    triggerCN: ['js-copy']
  };


  const handleClick = (trigger) => {
    navigator.clipboard.writeText(window.location.href);
    trigger.innerHTML = 'Copied!';

    setTimeout(() => {
      trigger.innerHTML = 'Copy link';
    }, 1500);
  };

  const build = () => {
    config.triggers.forEach(trigger => {
      trigger.addEventListener('click', () => handleClick(trigger));
    });
  };
  const init = () => {
    if (config.triggers.length > 0) {
      build();
    }
  };
  return {
    init,
  };
})();
export default Copy.init();
