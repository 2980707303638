import $ from 'jquery';
import axios from "axios";

const news = (() => {
  const config = {
    get triggers() {
      return this.triggerCN
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    triggerCN: ['js-news'],
    loadMoreButtonCN: ['load_more'],
    postContainer: 'post-container',
    loadingCN: 'loading',
    noMoreItemsCN: 'done',
  };

  const handleSuccess = (newsWrapper, response) => {
    // Update the parameters
    newsWrapper.dataset.offset =
      parseInt(newsWrapper.dataset.offset, 10) + response.data.items.length;
    newsWrapper.dataset.max = parseInt(response.data.max, 10);

    // Add the HTML
    response.data.items.forEach(content => {
      newsWrapper
        .getElementsByClassName(config.postContainer)[0]
        .insertAdjacentHTML('beforeend', content);
    });
  };

  const handleError = newsWrapper => {
    newsWrapper.innerHTML = 'Something went wrong, please try again later';
    // console.warn(newsWrapper, error);
  };

  const getNews = newsWrapper => {
    newsWrapper.classList.add(config.loadingCN);

    const documentLang = document.documentElement.lang;
    const lang =
      documentLang.indexOf('-') !== -1
        ? documentLang.slice(0, 2)
        : documentLang;

    axios
      .get(`/wp-json/v1/news?lang=${lang}`, {
        params: {
          offset: newsWrapper.dataset.offset,
          service: newsWrapper.dataset.service,
          industry: newsWrapper.dataset.industry,
        },
      })
      .then(response => {
        // console.dir(response);
        if (response.status === 200) {
          handleSuccess(newsWrapper, response);
        } else {
          handleError(newsWrapper, 'Something went wrong');
        }
      })
      .catch(error => {
        handleError(newsWrapper, error);
      })
      .then(() => {
        newsWrapper.classList.remove(config.loadingCN);

        const offset = parseInt(newsWrapper.dataset.offset, 10);
        const max = parseInt(newsWrapper.dataset.max, 10);

        if (offset >= max) newsWrapper.classList.add(config.noMoreItemsCN);
      });
  };

  const reset = newsWrapper => {
    newsWrapper.dataset.offset = 0;
    newsWrapper.dataset.max = 0;
    newsWrapper.getElementsByClassName(config.postContainer)[0].innerHTML = '';
    newsWrapper.classList.remove(config.noMoreItemsCN);
  };

  const changeSelect = (newsWrapper, select) => {
    const selectedOptions = Array.prototype.slice.call(select.selectedOptions);

    const selectedValues = selectedOptions.map(selectedOption => {
      return selectedOption.value;
    });

    newsWrapper.setAttribute(`data-${select.name}`, selectedValues.join());

    reset(newsWrapper);
    getNews(newsWrapper);
  };

  const init = () => {
    $(() => {
      if (config.triggers.length > 0) {
        document
          .querySelectorAll(`.${config.triggerCN}`)
          .forEach(newsWrapper => {
            newsWrapper
              .querySelectorAll(`.${config.loadMoreButtonCN}`)
              .forEach(btn => {
                btn.addEventListener('click', () => getNews(newsWrapper));
              });
            $('select').on('change', e => {
              changeSelect(newsWrapper, e.target);
            });
          });
      }
    });
  };

  return {
    init,
  };
})();

news.init();
