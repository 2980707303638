const ClickLabel = (() => {
  const config = {
    get labels() {
      return this.openLabelCN
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    openLabelCN: ['js-label-trigger'],
  };

  const clickLabel = clickEvent => {
    clickEvent.preventDefault();

    if (clickEvent.target) {
      const clickTarget = clickEvent.target
        .querySelector('label')
        .getAttribute('for');
      document.querySelector(`#${clickTarget}`).click();
    }
  };

  const build = () => {
    document.querySelectorAll(`.${config.openLabelCN}`).forEach(label => {
      label.addEventListener('click', clickEvent => {
        clickLabel(clickEvent);
      });
    });
  };

  const init = () => {
    if (config.labels.length > 0) {
      build();
    }
  };
  return {
    init,
  };
})();
export default ClickLabel.init();
