import { $, freeze, hasCookie, getCookie } from '../utils/Utils';

const cookiespopup = (() => {
  const config = {
    get triggers() {
      return this.triggerCN
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    triggerCN: ['cookiespopup'],
    get pages() {
      return this.pageCN
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    pageCN: ['cookiespopup__page'],
    popup: $('.js-cookiespopup')[0], // The popup
    closeCN: 'js-close', // The popup close (approve)
    nextCN: 'js-next',
    prevCN: 'js-prev',
    showCookiesCN: 'js-showcookies',
    showpageCN: 'cookiespopup__page--show',
    containerName: 'cookie_accept',
    body: $('body')[0], // The body
    bodyClassIgnores: ['error404'], // Pages that the popup shouldnt show on
    showCN: 'cookiespopup--show', // Class to be added on a popup that should be visible
    initChecked: true, // If the form should init with checkboxes marked as checked or not
    reloadOnSave: true, // If the page should reload on save
    freeze: false, // If the page should freeze
  };

  // get the checkbox data
  const getFormData = () => {
    const form = config.popup.querySelector(`form`);
    const obj = {};
    const formData = new FormData(form);

    Array.from(formData.keys()).forEach(key => {
      obj[key] = formData.get(key);
    });

    return JSON.stringify(obj);
  };

  const resetCookies = () => {
    const pattern = /^((wp|wordpress))/;
    document.cookie.split(';').forEach(c => {
      if (!pattern.test(c.trim()))
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, `=;expires= ${new Date().toUTCString()} ;path=/`);
    });
  };

  // Store the data in cookie or storage
  const store = () => {
    const now = new Date();
    now.setMonth(now.getMonth() + 1);

    // Set the cookie
    document.cookie = `${config.containerName}=${encodeURIComponent(
      getFormData(),
    )}; expires=${now.toUTCString()}; path=/`;
  };

  // Save, hide the popup and possibly reload page
  const save = e => {
    resetCookies();
    store();
    config.popup.classList.remove(config.showCN);
    if (config.freeze) freeze.stop();
    e.preventDefault();
    if (config.reloadOnSave) window.location.reload();
  };

  // Show nex page
  const next = e => {
    let done = false;

    config.pages.forEach((page, index) => {
      if (!done && page.classList.contains(config.showpageCN)) {
        page.classList.remove(config.showpageCN);
        config.pages[index + 1].classList.add(config.showpageCN);
        done = true;
      }
    });

    e.preventDefault();
  };

  // Show previous page
  const prev = e => {
    let done = false;

    config.pages.forEach((page, index) => {
      if (!done && page.classList.contains(config.showpageCN)) {
        page.classList.remove(config.showpageCN);
        config.pages[index - 1].classList.add(config.showpageCN);
        done = true;
      }
    });

    e.preventDefault();
  };

  // Show the popup
  const show = index => {
    if (config.freeze) freeze.start();
    config.popup.classList.add(config.showCN);
    config.pages[index].classList.add(config.showpageCN);
  };

  // Init the form based on cookie data
  const initForm = () => {
    const cookie = getCookie(config.containerName);
    if (cookie) {
      const cookieData = JSON.parse(cookie);

      Object.entries(cookieData).forEach(([key, value]) => {
        const input = config.popup.querySelector(`form input[name=${key}]`);
        if (input.type === 'checkbox') input.checked = value;
        else input.value = value;
      });
    } else if (config.initChecked) {
      config.popup
        .querySelectorAll(`form input[type=checkbox]`)
        .forEach(checkbox => {
          checkbox.checked = true; // eslint-disable-line no-param-reassign
        });
    }
  };

  // Show the settings-page directly
  const showSettings = () => {
    initForm();
    show(1);

    const backBtn = document.querySelectorAll(`.${config.prevCN}`);
    backBtn.forEach(btn => {
      btn.remove();
    });
  };

  // Check Storage, If no cookie , initiate the form and show popup
  const checkStorage = (index = 0) => {
    if (
      config.popup.classList.contains(config.showCN) ||
      (!(document.cookie && hasCookie(config.containerName)) &&
        !config.bodyClassIgnores.some(className =>
          config.body.classList.contains(className),
        ))
    ) {
      initForm();

      show(index);
    }
  };

  // Build, setup the event listeners and such
  const build = () => {
    config.triggers.forEach(trigger => {
      trigger.querySelectorAll(`.${config.closeCN}`).forEach(closeBtn => {
        closeBtn.addEventListener('click', e => save(e));
      });

      trigger.querySelectorAll(`.${config.nextCN}`).forEach(nextBtn => {
        nextBtn.addEventListener('click', e => next(e));
      });

      trigger.querySelectorAll(`.${config.prevCN}`).forEach(prevBtn => {
        prevBtn.addEventListener('click', e => prev(e));
      });

      checkStorage();
    });

    document
      .querySelectorAll(`.${config.showCookiesCN}`)
      .forEach(showCookiesBtn => {
        showCookiesBtn.addEventListener('click', () => showSettings());
      });
  };

  // Init the popup
  const init = () => {
    if (config.triggers.length > 0) {
      build();
    }
  };

  return {
    init,
  };
})();

cookiespopup.init();
