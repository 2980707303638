/* eslint no-param-reassign: ["error", { "props": false }] */
import Flickity from 'flickity';
import 'flickity-fade';

const Flick = (() => {
  const config = {
    get triggersCarousel() {
      return this.triggerCarouselCN
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    get navItems() {
      return this.navCN
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    triggerCarouselCN: ['carousel'],
    triggerNavCN: ['carousel__controls'],
    nextCN: ['carousel__controls--next'],
    prevCN: ['carousel__controls--prev'],
    navCN: ['carousel__controls--nav'],
    activeCN: ['carousel__controls--active'],
  };

  const buildCarousel = () => {
    config.triggersCarousel.forEach(trigger => {
      const flkty = new Flickity(trigger, {
        autoPlay: 5000,
        prevNextButtons: false,
        fade: true,
        wrapAround: false,
        pageDots: false,
        cellSelector: '.carousel__cell',
        setGallerySize: true,
        cellAlign: 'left',
      });

      trigger.querySelectorAll(`.${config.nextCN}`).forEach(nextBtn => {
        nextBtn.addEventListener('click', () => flkty.next());
      });

      trigger.querySelectorAll(`.${config.prevCN}`).forEach(prevBtn => {
        prevBtn.addEventListener('click', () => flkty.previous());
      });

      document.querySelectorAll(`.${config.navCN}`).forEach(navBtn => {
        const index = config.navItems.indexOf(navBtn);
        navBtn.addEventListener('click', () => flkty.select(index));
      });

      flkty.on('change', () => {
        document
          .querySelectorAll(`.${config.activeCN}`)[0]
          .classList.remove(config.activeCN);

        document
          .querySelectorAll(`.${config.navCN}`)
          [flkty.selectedIndex].classList.add(config.activeCN);
      });

      flkty.resize();
    });
  };

  const init = () => {
    if (config.triggersCarousel.length > 0) {
      buildCarousel();
    }
  };

  return {
    init,
  };
})();

export const queueFlick = () =>
  setTimeout(() => {
    Flick.init();
  }, 100);

export default Flick.init();
