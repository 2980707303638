const Nav = (() => {
  const config = {
    get navContainers() {
      return this.container
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    container: ['nav__side__container'],
    closeSideNav: ['nav__side--close'],
    langTrigger: 'nav__lang',
    jsNavOpen: 'js-nav-open',
    openMenu: 'menu-trigger',
    openLangMenu: 'child-lang',
    navChildren: 'nav__trigger--child',
    classActive: 'child-active',
    backArrow: 'nav__side--back',
    mainNav: 'nav__main',
    navWrapper: 'nav__wrapper',
    navInner: 'nav__inner',
  };

  const closeSideNav = clickEvent => {
    if (clickEvent.target.isEqualNode(config.navContainers[0])) {
      document.querySelector(`.${config.closeSideNav}`).click();
    }
  };

  const langMenu = () => {
    document.querySelector(`#${config.openMenu}`).checked = true;
    document.querySelector(`#${config.openLangMenu}`).click();
  };

  const openCurrentItems = () => {
    const currentMenuItem = document.querySelectorAll('.current-menu-item');

    currentMenuItem.forEach(item => {
      const itemChildrens = item.children;
      const childrensList = [...itemChildrens];

      childrensList.forEach(child => {
        if (child.classList.contains('js-label-trigger')) {
          child.click();
        }
      });
    });

    const childItem = document.querySelector(
      'input.nav__trigger--child.child-active',
    );

    if (childItem) {
      childItem.checked = true;
    }

    const openLangMenu = document.querySelector(`#${config.openLangMenu}`);

    if (openLangMenu.checked) {
      document.querySelector(`.${config.backArrow}`).click();
    }
  };

  const resetNavColors = () => {
    const navWrapper = document.querySelector(`.${config.navWrapper}`);
    const navInner = document.querySelector(`.${config.navInner}`);

    navWrapper.style.backgroundColor = 'inherit';
    navInner.classList.remove('nav__inner--dark');
  };

  const changeNavBackground = () => {
    const navWrapper = document.querySelector(`.${config.navWrapper}`);
    const navInner = document.querySelector(`.${config.navInner}`);

    navWrapper.style.backgroundColor = '#fff';
    navInner.classList.add('nav__inner--dark');

    navWrapper.addEventListener('mouseleave', () => {
      resetNavColors();
    });
  };

  const build = () => {
    config.navContainers[0].addEventListener('click', clickEvent => {
      closeSideNav(clickEvent);
    });

    document
      .querySelector(`.${config.langTrigger}`)
      .addEventListener('click', () => {
        langMenu();
      });

    document
      .querySelector(`.${config.jsNavOpen}`)
      .addEventListener('click', () => {
        openCurrentItems();
      });

    document
      .querySelector(`.${config.mainNav}`)
      .addEventListener('mouseenter', () => {
        changeNavBackground();
      });
  };

  const init = () => {
    if (config.navContainers.length > 0) {
      build();
    }
  };
  return {
    init,
  };
})();
export default Nav.init();
