const smoothScroll = (() => {
  const init = () => {
    document
      .querySelectorAll('a[href^="#"]:not(a[href="#"])')
      .forEach(anchor => {
        anchor.addEventListener('click', e => {
          const anchorTarget = document.querySelector(
            e.target.getAttribute('href'),
          );

          if (anchorTarget) {
            e.preventDefault();
            anchorTarget.scrollIntoView({
              behavior: 'smooth',
            });
          }
        });
      });
  };

  return {
    init,
  };
})();

smoothScroll.init();
