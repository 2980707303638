/* eslint no-param-reassign: ["error", { "props": false }] */

const Scrolldirection = (() => {
  const config = {
    scrollUpCN: ['scroll-up'],
    scrollTopCN: ['scroll-top'],
    scrollDownCN: ['scroll-down'],
    scrollTopLimit: 48,
  };

  let lastScrollTop = 0;

  const scroll = () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;

    if (st < config.scrollTopLimit) {
      document.body.classList.remove(config.scrollDownCN, config.scrollUpCN);
      document.body.classList.add(config.scrollTopCN);
    } else if (st > lastScrollTop) {
      document.body.classList.remove(config.scrollTopCN, config.scrollUpCN);
      document.body.classList.add(config.scrollDownCN);
    } else {
      document.body.classList.remove(config.scrollTopCN, config.scrollDownCN);
      document.body.classList.add(config.scrollUpCN);
    }

    lastScrollTop = st;
  };

  const init = () => {
    window.addEventListener('scroll', () => scroll());
    scroll();
  };

  return {
    init,
  };
})();

export default Scrolldirection.init();
