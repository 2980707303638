import { freeze } from '../utils/Utils';

const freezeNav = (() => {
  const config = {
    get triggers() {
      return this.triggerCN
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    triggerCN: ['js-sidenav'],
  };

  const freezeCheck = e => {
    if (e.target.checked) freeze.start();
    else freeze.stop();
  };

  const init = () => {
    if (config.triggers.length > 0) {
      document.querySelectorAll(`.${config.triggerCN}`).forEach(chkbox => {
        chkbox.addEventListener('change', e => freezeCheck(e));
      });
    }
  };

  return {
    init,
  };
})();

freezeNav.init();
