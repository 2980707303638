import axios from "axios";

const connectForm = (() => {
  const config = {
    get triggers() {
      return this.triggerCN
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    triggerCN: ['js-connect'],
  };

  const validate = () => {
    // TODO: Add validation functionality
    return true;
  };

  const handleSuccess = e => {
    e.target.classList.add('success');
  };

  const handleError = e => {
    e.target.classList.add('error');
  };

  const submit = e => {
    e.preventDefault();

    if (!validate()) return false;

    const formEntries = new FormData(e.target).entries();
    const json = Object.assign(
      ...Array.from(formEntries, ([x, y]) => ({ [x]: y })),
    );

    axios
      .post('/wp-json/v1/form/connect', null, {
        params: json,
      })
      .then(response => {
        if (response.status === 200) {
          handleSuccess(e);
        }
      })
      .catch(err => {
        handleError(e, err);
      });

    return true;
  };

  const init = () => {
    if (config.triggers.length > 0) {
      document.querySelectorAll(`.${config.triggerCN}`).forEach(form => {
        form.addEventListener('submit', e => submit(e));
      });
    }
  };

  return {
    init,
  };
})();

connectForm.init();
