import $ from 'jquery';
import 'select2';

const Select = () => {
  $(() => {
    $('.js-select__industry')
      .select2({
        width: '100%',
        selectionCssClass: 'select',
        placeholder: 'Industries',
      })
      .on('select2:opening select2:closing', function hideSearchbox() {
        const $searchfield = $(this)
          .parent()
          .find('.select2-search__field');
        $searchfield.prop('disabled', true);
      })
      .on('select2:unselecting', function unselect() {
        $(this).data('state', 'unselected');
      })
      .on('select2:open', function close() {
        if ($(this).data('state') === 'unselected') {
          $(this).removeData('state');
          const self = $(this);
          self.select2('close');
        }
      });

    $('.js-select__service')
      .select2({
        width: '100%',
        selectionCssClass: 'select',
        placeholder: 'Services',
      })
      .on('select2:opening select2:closing', function hideSearchbox() {
        const $searchfield = $(this)
          .parent()
          .find('.select2-search__field');
        $searchfield.prop('disabled', true);
      })
      .on('select2:unselecting', function unselect() {
        $(this).data('state', 'unselected');
      })
      .on('select2:open', function close() {
        if ($(this).data('state') === 'unselected') {
          $(this).removeData('state');
          const self = $(this);
          self.select2('close');
        }
      });

    $('.select2-search__field').attr('readonly', true);
  });
};

export default Select();
